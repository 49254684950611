import axios from "axios";

const baseURL = process.env.VUE_APP_BASE_URL;

const http = axios.create({
    baseURL,
    withCredentials: true,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
})


// 请求
http.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.error(error);
    }
);

// 响应
http.interceptors.response.use(
    res => {
        return res.data;
    },
    error => {

    }
)
export default http;
